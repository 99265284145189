<ion-content class="app">
  <div class="app__header hasDescription">
    <span class="back-button">
      <ion-back-button mode="md" defaultHref="settings"></ion-back-button>
    </span>
    <h1>{{ 'page.settings.permissions' | translate }}</h1>
  </div>
  <div class="app__description">
    {{ 'page.settings.permissions.desc' | translate }}
  </div>
  <div class="app__content permissions" [formGroup]="permissionsForm">
    <div class="permissions__box">
      <div class="permissions__box__content">
        <h2>{{ 'settings.permissions.notifications.title' | translate }}</h2>
        <p>{{ 'settings.permissions.notifications.desc' | translate }}</p>
      </div>
      <div class="permissions__box__toggle">
        <ion-toggle formControlName="notificationsEnabled"></ion-toggle>
      </div>
    </div>

    <div class="permissions__box">
      <div class="permissions__box__content">
        <h2>{{ 'settings.permissions.location.title' | translate }}</h2>
        <p>{{ 'settings.permissions.location.desc' | translate }}</p>
      </div>
      <div class="permissions__box__toggle">
        <ion-toggle formControlName="locationEnabled"></ion-toggle>
      </div>
    </div>
  </div>
</ion-content>
