import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[tabindex="0"]',
  standalone: true,
})
export class DivClickDirective {
  @HostListener('keydown', ['$event'])
  handleKeydown(event: KeyboardEvent): void {
    if (event.key === 'Enter' && event.target instanceof HTMLElement) {
      const element = event.target;
      element.click();
      event.preventDefault();
    }
  }
}
