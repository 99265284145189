import { Injectable } from '@angular/core';
import { Project } from '../interfaces/project';
import { LocationService } from './location.service';
import { Metrics } from '../interfaces/metrics';
import { StorageService } from './storage.service';
import { Platform } from '@ionic/angular/standalone';
import { v4 as uuidv4 } from 'uuid';
import { CurrentWhiteLabelApplication } from '../utils/current-white-label-application';
import { ApiService } from './api.service';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { WhiteLabelApplicationService } from './white-label-application.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, of } from 'rxjs';
import { ProjectService } from './project.service';
import { AnalyticsType } from '../enums/analytics-type';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  private static readonly STORAGE_KEY = 'analyticsData'; // Key for localStorage
  private batchSize = 100;
  private analyticsData: Metrics[] = [];
  private userId: string;
  private lastMatchedRoute: string;

  constructor(
    private locationService: LocationService,
    private storageService: StorageService,
    private platform: Platform,
    private apiService: ApiService,
    private router: Router,
    private currentWhiteLabelApplication: CurrentWhiteLabelApplication,
    private whiteLabelApplicationService: WhiteLabelApplicationService,
    private http: HttpClient
  ) {}

  public async initAnalytics() {
    this.storageService.get(AnalyticsService.STORAGE_KEY).then((storedData) => {
      if (storedData) {
        this.analyticsData = JSON.parse(storedData);
      }
    });

    this.userId = (await this.storageService.get('userId')) ?? uuidv4();
    this.storageService.set('userId', this.userId);

    this.platform.pause.subscribe(() => {
      this.sendAnalyticsData();
    });

    this.router.events.subscribe(() => {
      const rootRoute = this.router.routerState.snapshot.root;
      this.lastMatchedRoute = this.getLastMatchedRoute(rootRoute);
    });

    setInterval(() => this.sendAnalyticsData(), 10000);
  }

  getLastMatchedRoute(route: ActivatedRouteSnapshot): string {
    let url = '';
    let currentRoute = route;
    while (currentRoute.firstChild) {
      if (currentRoute.routeConfig && currentRoute.routeConfig.path) {
        const path = currentRoute.routeConfig.path.replace(/\/:[^/]+/g, ''); // Remove route parameters
        url = path || url; // Update url only if path is not empty
      }
      currentRoute = currentRoute.firstChild;
    }
    return url;
  }

  public async logEvent(
    eventName: AnalyticsType,
    parameters: any,
    project: Project = null
  ) {
    await this.apiService.loadDeviceInfo();
    const pageName = this.lastMatchedRoute ?? 'home';
    try {
      let location: GeolocationPosition | null = null;

      try {
        location =
          this.locationService.lastPosition ??
          (await this.locationService.getCurrentPosition());
      } catch (error) {
        // no location available, but continue
      }

      const staticFields = {
        latitude: location ? location.coords.latitude : 0,
        longitude: location ? location.coords.longitude : 0,
        device_os: this.apiService.getOs(),
        app_name: this.currentWhiteLabelApplication.getName(),
        project_id: project ? project.id : 0,
        project_slug: project ? project.slug : '',
        developer_id: project && project.customer ? project.customer.id : 0,
        page_name: pageName.charAt(0).toUpperCase() + pageName.slice(1),
        app_path: this.router.url,
        bundle_id: this.currentWhiteLabelApplication.getBundleIdentifer(),
      };

      if (project) {
        const target = await this.storageService.get(
          ProjectService.PROJECT_TARGET_KEY + '- ' + project.slug
        );

        if (target) {
          parameters.target = target;
        }
      }

      this.trackEvent(eventName, staticFields, parameters);
    } catch (error) {
      console.warn(error);
    }
  }

  async trackEvent(
    eventName: string,
    eventData: any,
    dynamicFields: any
  ): Promise<void> {
    if (this.userId === undefined) {
      await this.initAnalytics();
    }
    await this.whiteLabelApplicationService.waitUntilReady();
    this.analyticsData.push({
      user_id: this.userId,
      createdAt: new Date()
        .toISOString()
        .replace('T', ' ')
        .replace('Z', '')
        .slice(0, 19),
      metric: eventName,
      app_version: this.apiService.getVersion(),
      ...eventData,
      dynamicFields: dynamicFields,
    });

    this.storageService.set(
      AnalyticsService.STORAGE_KEY,
      JSON.stringify(this.analyticsData)
    );

    if (this.analyticsData.length >= this.batchSize) {
      this.sendAnalyticsData();
    }
  }

  getAnalyticsData(): any[] {
    return this.analyticsData;
  }

  private async sendAnalyticsData(): Promise<void> {
    if (this.analyticsData.length === 0) {
      return;
    }

    this.http
      .post(environment.insightsEndpoint, this.analyticsData)
      .pipe(catchError(() => of(null)))
      .subscribe();

    this.clearAnalyticsData();
  }

  private clearAnalyticsData(): void {
    this.analyticsData = [];

    this.storageService.set(AnalyticsService.STORAGE_KEY, '');
  }
}
