<div [class.card--summary__inner]="withPadding">
  <div class="card--summary__head">
    <div>
      <h2>{{ 'feed.rating.title' | translate }}</h2>
    </div>
  </div>
  <ng-container *ngIf="!invalid; else invalidContent">
    <app-loader [loading]="loading"></app-loader>
    <ng-container *ngIf="!loading && !sent">
      <div class="card--summary__description">
        <p>{{ 'feed.rating.description.new' | translate }}</p>
      </div>
      <form [formGroup]="form">
        <div>
          <div class="opinion__options__item" [form]="form">
            <h2>{{ theme.title }}</h2>
            <app-smiley-input
              [disabled]="reacted"
              formControlName="score"
            ></app-smiley-input>
          </div>
        </div>
      </form>
    </ng-container>
    <div class="opinion__button" *ngIf="reacted">
      <button role="link" class="card--summary__button" (click)="open()">
        <span>{{ 'feed.rating.action' | translate }}</span>
        <span
          class="icon"
          [inlineSVG]="'/assets/icons/card/arrow-right.svg'"
        ></span>
      </button>
    </div>
  </ng-container>
  <ng-template #invalidContent>
    <div class="opinion__invalid">
      <ng-container *ngIf="sent && !loading">
        <div class="card--summary__description">
          <span>{{ 'feed.rating.sent.desc' | translate }}</span>
        </div>
      </ng-container>
      <ng-container *ngIf="!sent">
        <div class="card--summary__description">
          <span>{{ 'project.opinion.inactive.title' | translate }}</span>
        </div>
      </ng-container>
    </div>
  </ng-template>
</div>
