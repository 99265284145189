<ion-content class="app">
  <div class="app__header" role="banner">
    <span class="back-button">
      <ion-back-button defaultHref="settings" mode="md"></ion-back-button>
    </span>
  </div>
  <div class="app__content profile">
    <div class="profile__content">
      <div *ngIf="sent" class="empty-list">
        <span inlineSVG="/assets/icons/inbox.svg"></span>
        <h1>{{ 'page.login.email.sent.title' | translate }}</h1>
        <p>
          {{ 'page.login.email.sent.desc' | translate: {email:
          loginForm.get('email').value } }}
        </p>
      </div>
      <div *ngIf="!sent" class="login">
        <h1>{{ 'page.login.email.title' | translate }}</h1>
        <img alt="{{ 'Logo ' + appName }}" *ngIf="appIcon" [src]="appIcon" />
        <p>{{ 'page.login.email.desc' | translate }}</p>
        <form [formGroup]="loginForm">
          <div class="login__form">
            <div *ngIf="failed" class="error">
              {{ 'page.login.email.error'| translate }}
            </div>
            <input
              type="email"
              formControlName="email"
              [placeholder]="'page.login.email.placeholder' | translate"
            />
            <button
              [disabled]="loginForm.invalid || loading"
              class="button button--login"
              (click)="login()"
            >
              {{ 'page.login.email.button' | translate }}
            </button>
            <button
              class="button button--back"
              routerDirection="back"
              [routerLink]="['..']"
            >
              {{ 'page.login.email.back' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ion-content>
