import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Customer } from 'src/app/interfaces/customer';
import { CurrentWhiteLabelApplication } from 'src/app/utils/current-white-label-application';
import { TruncateModule } from '@yellowspot/ng-truncate';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateTextDirective } from '../../../../directives/translate-text.directive';
import { NgIf } from '@angular/common';
import { DivClickDirective } from 'src/app/directives/div-click.directive';

@Component({
  selector: 'app-card-organization',
  templateUrl: './card-organization.component.html',
  standalone: true,
  imports: [
    NgIf,
    TranslateTextDirective,
    DivClickDirective,
    TranslateModule,
    TruncateModule,
  ],
})
export class CardOrganizationComponent {
  @Input() customer: Customer;
  @Input() language: string;
  @Output() openLink = new EventEmitter<void>();

  constructor(protected app: CurrentWhiteLabelApplication) {}

  open() {
    this.openLink.next();
  }
}
