<div class="social-buttons">
  <button
    *ngFor="let type of likeTypes; let i = index"
    [attr.aria-label]="'update.like.type.' + type.key | translate"
    (click)="toggleLike(type.key)"
    [attr.data-type]="type.key"
    [class]="type.key"
    class="ion-activatable"
    [class.active]="update.likedBy !== 0 && likedType === type.key"
  >
    <ion-ripple-effect class="ripple"></ion-ripple-effect>
    <div [inlineSVG]="'/assets/icons/card/actions/' + type.key + '.svg'"></div>
    <span class="count" [class.show]="type.value > 0">
      {{ type.value }}
    </span>
  </button>
</div>
