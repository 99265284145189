import { EventEmitter, Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ApiService } from './api.service';
import { Address } from 'src/app/interfaces/address';
import mapboxgl, { LngLat } from 'mapbox-gl';
import { CurrentWhiteLabelApplication } from '../utils/current-white-label-application';

@Injectable({ providedIn: 'root' })
export class DeviceService {
  public addressUpdated$ = new EventEmitter<Address>();
  public defaultLocation = new mapboxgl.LngLat(4.895168, 52.370216);

  constructor(
    private apiService: ApiService,
    private currentApp: CurrentWhiteLabelApplication
  ) {}

  public getData(urlParams?: HttpParams, location?: LngLat): Promise<any> {
    return new Promise(async (resolve, reject) => {
      let params = urlParams ? urlParams : new HttpParams();
      if (params.get('project_ids') && location) {
        params = params
          .set('location[lat]', location.lat.toString())
          .set('location[lng]', location.lng.toString());
      }

      try {
        const response = await this.apiService
          .identifiedGet('/api/v3/device/data', params)
          .toPromise();
        if (response.location)
          this.defaultLocation = new LngLat(
            response.location.lng,
            response.location.lat
          );
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  public listAddresses(): Promise<Address[]> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.apiService
          .identifiedGet('/api/v3/device-address')
          .toPromise();
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  public getAddress(id: number): Promise<Address> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.apiService
          .identifiedGet('/api/v3/device-address/' + id)
          .toPromise();
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  public saveAddress(address: Address) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.apiService
          .identifiedPost('/api/v3/device-address', address)
          .toPromise();
        this.addressUpdated$.next(response);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  public updateAddress(id: number, address: Address) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.apiService
          .identifiedPut('/api/v3/device-address/' + id, address)
          .toPromise();
        this.addressUpdated$.next(response);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  public deleteAddress(address: Address) {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await this.apiService
          .identifiedDelete('/api/v3/device-address/' + address.id)
          .toPromise();
        resolve(response);
      } catch (error) {
        reject(error);
      }
    });
  }

  public async syncWhiteLabelApplication() {
    try {
      await this.apiService
        .identifiedPut(
          '/api/v3/devices/' +
            (await this.apiService.getServerSecret()) +
            '/whitelabel',
          {
            whiteLabelApplication:
              '/api/white-label-applications/' + this.currentApp.getApiKey(),
          }
        )
        .toPromise();
    } catch (error) {
      // ignore
    }
  }
}
