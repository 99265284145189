<div
  class="card__content"
  [attr.data-update]="update.id"
  [attr.data-project]="project?.slug"
>
  <h2 class="visually-hidden">
    {{ project?.name || update?.customer?.name }}
    {{ 'projects.update' | translate }}
  </h2>
  <div
    [appTranslateText]="update['contentText']"
    (click)="toggleReadMore()"
    [language]="language"
    [update]="update"
    [html]="
      update['translatedContent'] || update['contentText']
        | truncate : getTruncateLength()
        | readMore
          : update['translatedContent']?.length || update['contentText']?.length
    "
  ></div>
</div>

<div class="card__events" *ngIf="update.events?.length">
  <app-events
    [events]="update.events"
    [update]="update"
    (expand)="toggleReadMore()"
    [language]="update.project.language"
  ></app-events>
</div>

<div class="card__media" *ngIf="update.attachments.length">
  <app-media-slider
    [attachments]="update.attachments"
    [project]="project"
  ></app-media-slider>
</div>

<div class="card__bottom">
  <app-social-buttons
    [project]="project"
    [update]="update"
    [likedType]="likedType"
  ></app-social-buttons>
  <app-poll
    *ngIf="update.poll"
    [update]="update"
    [poll]="update.poll"
    [project]="project"
  ></app-poll>
</div>
