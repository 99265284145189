<div class="card" [ngClass]="className">
  <div (click)="openProject(project)" *ngIf="project && withCoverImage">
    <div
      class="card__cover-image"
      [style.background-image]="
        project.coverImageThumbnails
          ? type === CardType.PROJECT_SUGGESTION
            ? 'url(' + project.coverImageThumbnails.small + ')'
            : 'url(' + project.coverImageThumbnails.medium + ')'
          : 'none'
      "
    ></div>
  </div>
  <div class="card__head" *ngIf="(project || update?.customer) && withHeader">
    <div tabindex="0" role="link" (click)="openItemOrigin()">
      <div class="card__image default" [class.small]="type === CardType.EVENTS">
        <img
          *ngIf="project?.logoThumbnails"
          appImgFallback
          [src]="project.logoThumbnails.small"
          width="40"
          height="40"
          alt=""
        />
        <img
          *ngIf="!project?.logoThumbnails"
          appImgFallback
          [src]="
            project?.customer.logoThumbnails?.small ||
            update?.customer?.logoThumbnails?.small
          "
          width="40"
          height="40"
          alt=""
        />
      </div>
      <div class="card__title">
        <span>
          <span>{{ project?.name || update.customer?.name }}</span>
          <span aria-hidden="true">{{ project?.participants }}</span>
        </span>
        <span *ngIf="withFollowers"
          ><ng-container *ngIf="dateAdded">{{
            dateAdded | date : 'longDate'
          }}</ng-container>
          <ng-container *ngIf="dateAdded && project.amountOfFollowers">
            •
          </ng-container>
          <ng-container *ngIf="project.amountOfFollowers">{{
            (project.amountOfFollowers == 1
              ? 'card.followers.singular'
              : 'card.followers.plural'
            ) | translate : { followers: project.amountOfFollowers }
          }}</ng-container></span
        >
        <span *ngIf="!withFollowers">
          <ng-container *ngIf="dateAdded">
            {{ dateAdded | date : 'longDate' }}
            •
            {{ dateAdded | date : 'shortTime' }}
          </ng-container>
        </span>
      </div>
    </div>
    <div
      (click)="share($event)"
      tabindex="0"
      role="button"
      class="card__share"
      [attr.aria-label]="'button.share' | translate"
      *ngIf="type == CardType.UPDATE && canShare"
    >
      <span [inlineSVG]="'/assets/icons/card/share.svg'"></span>
    </div>
  </div>

  <!-- Event Card -->
  <app-card-events
    *ngIf="type === CardType.EVENTS"
    [project]="project"
    [events]="events"
  ></app-card-events>

  <!-- Update Card -->
  <app-card-update
    *ngIf="type === CardType.UPDATE && update"
    [update]="update"
    [project]="project"
    [language]="language"
    [likedType]="likedType"
    (openLink)="openItem()"
  ></app-card-update>

  <!-- Survey Card -->
  <app-card-survey
    *ngIf="type === CardType.SURVEY"
    [withPadding]="!withHeader"
    [language]="language"
    [survey]="survey"
    (openLink)="openItem()"
  ></app-card-survey>

  <!-- Rating Card -->
  <app-card-rating
    *ngIf="type === CardType.RATING"
    [withPadding]="!withHeader"
    [language]="language"
    [project]="project"
    (openLink)="openItem()"
  ></app-card-rating>

  <!-- Welcome Card -->
  <app-card-welcome *ngIf="type === CardType.WELCOME"></app-card-welcome>

  <!-- Contact Card -->
  <app-card-contact
    *ngIf="type === CardType.CONTACT"
    (openLink)="openItem(CardType.CONTACT)"
    [withLink]="withLink"
    [project]="project"
  ></app-card-contact>

  <!-- Child Project Card -->
  <app-card-child-project
    *ngIf="type === CardType.CHILDPROJECT"
    [project]="childProject"
    (openLink)="openItem()"
  ></app-card-child-project>

  <!-- Project Suggestion Card -->
  <app-card-project-suggestion
    *ngIf="type === CardType.PROJECT_SUGGESTION"
    [language]="language"
    [project]="project"
    (openLink)="openItem()"
  ></app-card-project-suggestion>

  <!-- Organization Card -->
  <app-card-organization
    *ngIf="type === CardType.ORGANIZATION"
    [language]="language"
    [customer]="project.customer"
    (openLink)="openItem()"
  ></app-card-organization>

  <!-- Custom Card -->
  <ng-content></ng-content>
</div>
