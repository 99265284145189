<button
  *ngIf="titles"
  [class.loading]="loading"
  [attr.disabled]="loading ? true : null"
  (click)="doTranslate($event)"
  class="translation-button"
>
  <span [inlineSVG]="'/assets/icons/world.svg'"></span>
  <span *ngIf="!translated">{{ titles.show }}</span>
  <span *ngIf="translated">{{ titles.original }}</span>
  <app-loader name="dots" color="dark" [loading]="loading"></app-loader>
</button>
