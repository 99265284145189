import { HttpParams } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import {
  ActionSheetButton,
  ActionSheetController,
  Platform,
} from '@ionic/angular/standalone';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { CardType } from 'src/app/enums/card-type';
import { Project } from 'src/app/interfaces/project';
import { Update } from 'src/app/interfaces/update';
import { AnalyticsService } from 'src/app/services/analytics.service';
import { UpdateService } from 'src/app/services/update.service';
import { DeviceService } from 'src/app/services/device.service';
import { WidgetService } from 'src/app/services/widget.service';
import { Customer } from 'src/app/interfaces/customer';
import { AnalyticsType } from 'src/app/enums/analytics-type';
import { CardComponent } from '../cards/card/card.component';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgIf, NgFor } from '@angular/common';
import {
  IonInfiniteScroll,
  IonInfiniteScrollContent,
} from '@ionic/angular/standalone';
import { DivClickDirective } from 'src/app/directives/div-click.directive';

@Component({
  selector: 'app-update-list',
  templateUrl: './update-list.component.html',
  standalone: true,
  imports: [
    NgIf,
    InlineSVGModule,
    NgFor,
    CardComponent,
    TranslateModule,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    DivClickDirective,
  ],
})
export class UpdateListComponent implements OnInit {
  @Input() updates: Update[];
  @Input() totalUpdates: number;
  @Input() project: Project = null;
  @Input() customer: Customer = null;
  @Input() updateDeviceData: any = {};
  CardType = CardType;
  categories: string[];
  activeCategory: string;
  ready: boolean;
  page: number;
  perPage = 7;
  isEmbedded: boolean;

  private seenUpdates: Array<Update> = [];

  constructor(
    private translateService: TranslateService,
    private analyticsService: AnalyticsService,
    private updateService: UpdateService,
    private actionSheetCtrl: ActionSheetController,
    private deviceService: DeviceService,
    private platform: Platform,
    private widgetService: WidgetService
  ) {
    this.isEmbedded = this.widgetService.getIsEmbedded();
  }

  async ngOnInit() {
    if (this.platform.is('desktop')) this.perPage *= 3;
    this.page = 1 + Math.ceil(this.updates.length / this.perPage);
    try {
      this.categories = await this.updateService.getUpdateCategories();
    } catch (error) {
      // ignore
    }

    this.ready = true;
  }

  onItemVisible(item: Update) {
    const isNew = !this.seenUpdates.includes(item);
    if (isNew) {
      this.analyticsService.logEvent(
        AnalyticsType.UPDATE_VIEW,
        {
          update_id: item.id,
        },
        this.project ?? item.project
      );
      this.seenUpdates = [...this.seenUpdates, ...[item]];
    }
  }

  async loadUpdates(event = null) {
    let newUpdates: Update[];

    try {
      const updateResponse = this.project
        ? await this.updateService.getAllUpdates(
            this.project,
            this.setUrlParams()
          )
        : await this.updateService.getAllUpdatesForCustomer(
            this.customer,
            this.setUrlParams()
          );

      newUpdates = updateResponse.member;

      this.updates =
        this.page === 1 ? newUpdates : this.updates.concat(newUpdates);
      const updates = await this.updateService.filterUpdatesByTargetGroup(
        this.updates,
        this.project
      );
      const filteredUpdatesCount = this.updates.length - updates.length;
      this.updates = updates;
      this.totalUpdates = updateResponse.totalItems - filteredUpdatesCount;
      this.page++;
    } catch (error) {
      // ignore
    } finally {
      if (event) event.target.complete();
      this.getUpdatesDeviceData(newUpdates);
    }
    this.ready = true;
  }

  updateFilter(category: string) {
    this.ready = false;
    this.activeCategory = category;
    this.page = 1;
    this.loadUpdates();
  }

  async openFilter() {
    const buttons: ActionSheetButton[] = [
      {
        text: this.translateService.instant('feed.filter.all'),
        cssClass: !this.activeCategory ? 'activeBtn' : 'filterBtn',
        handler: () => {
          this.updateFilter(null);
        },
      },
    ];

    Object.keys(this.categories).forEach((categoryId) => {
      buttons.push({
        text: this.categories[categoryId],
        cssClass: this.activeCategory == categoryId ? 'activeBtn' : 'filterBtn',
        handler: () => {
          this.updateFilter(categoryId);
        },
      });
    });

    buttons.push({
      text: this.translateService.instant('feed.filter.cancel'),
      role: 'cancel',
      cssClass: 'cancelBtn',
    });

    const actionSheet = await this.actionSheetCtrl.create({
      header: this.translateService.instant('feed.filter'),
      buttons: buttons,
    });

    await actionSheet.present();
  }

  setUrlParams() {
    let params = new HttpParams()
      .set('page', this.page.toString())
      .set('perPage', this.perPage.toString());
    if (this.activeCategory)
      params = params.set('category', this.activeCategory);
    return params;
  }

  async getUpdatesDeviceData(newUpdates: Update[]) {
    const updateIds = newUpdates?.map(({ id }) => id).join(',');
    const params = new HttpParams().set('update_ids', updateIds);
    try {
      const response = await this.deviceService.getData(params);
      this.updateDeviceData = response.updates;
    } catch (error) {
      // ignore
    }
  }
}
