<div class="card__poll">
  <span>{{ poll.title }}</span>
  <p class="poll_closed_text" *ngIf="poll.closed">
    {{ 'poll.closed' | translate }}
  </p>
  <ul class="card__poll__questions">
    <ng-container *ngFor="let choice of poll.choices; let index = index">
      <li class="default" [class.active]="choice.active && this.showResult">
        <input
          type="radio"
          [formControl]="control"
          name="{{ uniquePrefix }}{{ poll['@id'] }}"
          id="{{ uniquePrefix }}pollChoice{{ choice.id }}"
          [value]="choice"
          [attr.disabled]="showResult ? true : null"
        />
        <label
          [class.poll_closed]="poll.closed"
          for="{{ uniquePrefix }}pollChoice{{ choice.id }}"
          [attr.tabindex]="showResult ? null : 0"
          (keydown)="$event.key === 'Enter' ? $event.target.click() : true"
        >
          <span
            *ngIf="choice.filePath != null"
            [style.background-image]="choice.image"
            [attr.aria-label]="choice.text"
            class="card__poll__questions__image"
          >
            <span
              [hidden]="!showResult"
              [style.min-width]="choice.percentageSize + '%'"
              >{{ choice.percentage }} %</span
            >
          </span>
          <span class="card__poll__questions__text">{{ choice.text }}</span>
          <strong [hidden]="!showResult">({{ choice.percentage }}%)</strong>
        </label>
      </li>
    </ng-container>
  </ul>
  <div class="card__poll__votes" *ngIf="showResult">
    {{
      (totalVotes === 1 ? 'poll.votes.singular' : 'poll.votes.plural')
        | translate : { votes: totalVotes }
    }}
  </div>
</div>
